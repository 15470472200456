export const realtorData = {
  email: 'cproencina@unne.cl',
  phone:'+56984713913'
  // email: 'contacto@accionpanal.com',
};

export const footerData = {
  email: 'cproencina@unne.cl',
  phone:'984713913',
  wsp:'984713913',
}
